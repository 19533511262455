import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import HeaderFooterLayout from '@nib-components/header-footer-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#installation",
        "aria-label": "installation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Installation`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @nib-components/header-footer-layout
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: You will also need to install the peerDependencies `}<a parentName="p" {...{
          "href": "/components/icons/"
        }}>{`@nib/icons`}</a>{`, `}<a parentName="p" {...{
          "href": "/components/theme/"
        }}>{`@nib-components/theme`}</a>{` and also `}<inlineCode parentName="p">{`@nib/chatbot-launcher`}</inlineCode>{` if you wish to use the nibby chatbot.`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import HeaderFooterLayout from '@nib-components/header-footer-layout';
`}</code></pre>
    <h2 {...{
      "id": "interactive-demo",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#interactive-demo",
        "aria-label": "interactive demo permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Interactive demo`}</h2>
    <h3 {...{
      "id": "headerfooterlayout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#headerfooterlayout",
        "aria-label": "headerfooterlayout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`HeaderFooterLayout`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<HeaderFooterLayout variation="arhi">
  <div>Page content here</div>
</HeaderFooterLayout>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#props",
        "aria-label": "props permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`children`}</inlineCode>{` `}<em parentName="td">{`(required)`}</em></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The contents of the page within the layout.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`headerConfig`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specify a custom configuration for the Header. See `}<a parentName="td" {...{
              "href": "https://www.meshdesignsystem.com/components/header/#props"
            }}>{`Header's `}<inlineCode parentName="a">{`config`}</inlineCode>{` prop`}</a>{` for more.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`footerConfig`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Specify a custom configuration for the Footer. See `}<a parentName="td" {...{
              "href": "https://www.meshdesignsystem.com/components/footer/#props"
            }}>{`Footer's `}<inlineCode parentName="a">{`config`}</inlineCode>{` prop`}</a>{` for more.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`variation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`arhi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Which version of the header/footer to use. One of `}<inlineCode parentName="td">{`arhi`}</inlineCode>{`, `}<inlineCode parentName="td">{`nz`}</inlineCode>{`, `}<inlineCode parentName="td">{`shareholders`}</inlineCode>{`, `}<inlineCode parentName="td">{`international`}</inlineCode>{`, `}<inlineCode parentName="td">{`foundation`}</inlineCode>{`, `}<inlineCode parentName="td">{`providers`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The header component to use within the layout. This prop allows you to define a custom combination of Header/SmallHeader and Footer/SmallFooter in the layout.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`footer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Footer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The footer component to use within the layout. This prop allows you to define a custom combination of Header/SmallHeader and Footer/SmallFooter in the layout.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`smallLayout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether the layout should use the SmallHeader and SmallFooter components.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`showSmallHeaderMenu`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A pass-through prop to the `}<inlineCode parentName="td">{`showMenu`}</inlineCode>{` prop in the `}<inlineCode parentName="td">{`SmallHeader`}</inlineCode>{` component to be used in conjunction with `}<inlineCode parentName="td">{`smallLayout`}</inlineCode>{`. By default the small header footer layout shows no menu in the header. Set to `}<inlineCode parentName="td">{`true`}</inlineCode>{` to use the config provided by the variation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`flex`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether the underlying `}<inlineCode parentName="td">{`GrowLayout.Content`}</inlineCode>{` should use CSS flex.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`isCorporate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use the corporate phone number from the variation config, if it exists. Otherwise use default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`invertSmallHeader`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether to apply the `}<inlineCode parentName="td">{`invert`}</inlineCode>{` prop to the `}<inlineCode parentName="td">{`SmallHeader`}</inlineCode>{` when it is displayed. This could be when `}<inlineCode parentName="td">{`smallLayout`}</inlineCode>{` is true, or on any screen below the XL breakpoint.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`withAbsoluteLinks`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not header link urls should be absolute.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`absoluteBaseUrl`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`To be used in conjunction with `}<inlineCode parentName="td">{`withAbsoluteLinks`}</inlineCode>{`. Each variation has a default baseUrl in config. This is to override that default.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enableSearch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When enabled, a search icon link will be shown. Clicking this link will open the nib `}<inlineCode parentName="td">{`/search`}</inlineCode>{` page.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`hideHeaderPhoneNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not to render the phone number link in the Header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`hideSmallFooterPhoneNumber`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Whether or not to hide the phone number in the small footer.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`enableNibby`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`When enabled, the nibby launcher button will be shown fixed, in the bottom right hand corner of the screen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`nibbyOptions`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional config to pass through to the chatbot-launcher component. See `}<a parentName="td" {...{
              "href": "https://github.com/nib-group/chatbot-client-monorepo/tree/master/packages/launcher#options"
            }}>{`chatbot-launcher docs`}</a>{` for options.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`forceHideAcknowledgementOfCountry`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An override to hide the `}<inlineCode parentName="td">{`AcknowledgementOfCountry`}</inlineCode>{`, no matter the config, variation or brand.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#considerations",
        "aria-label": "considerations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Considerations`}</h2>
    <p>{`For sales funnels and landing pages that do not require the full navigation you should enable the `}<inlineCode parentName="p">{`smallLayout`}</inlineCode>{` prop to use the layout with a `}<a parentName="p" {...{
        "href": "/components/header/#smallheader"
      }}>{`SmallHeader`}</a>{` and `}<a parentName="p" {...{
        "href": "/components/footer/#smallfooter"
      }}>{`SmallFooter`}</a>{`.`}</p>
    <p>{`In certain cases, you may want a custom combination of Header/SmallHeader and Footer/SmallFooter, or a completely custom header of footer component. To achieve this, you can pass through the component you want in your layout down through the `}<inlineCode parentName="p">{`header`}</inlineCode>{` and `}<inlineCode parentName="p">{`footer`}</inlineCode>{` props.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`showSmallHeaderMenu`}</inlineCode>{` only takes effect when used alongside the `}<inlineCode parentName="p">{`smallLayout`}</inlineCode>{` prop. This prop will pass the `}<inlineCode parentName="p">{`showMenu`}</inlineCode>{` prop down to the underlying `}<a parentName="p" {...{
        "href": "/components/header/#smallheader"
      }}>{`SmallHeader`}</a>{` component. Note that the menu is always included for the `}<inlineCode parentName="p">{`Header`}</inlineCode>{` dropping down to the `}<inlineCode parentName="p">{`SmallHeader`}</inlineCode>{` on smaller screens.`}</p>
    <h3 {...{
      "id": "responsive-navigation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#responsive-navigation",
        "aria-label": "responsive navigation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Responsive navigation`}</h3>
    <p>{`Our responsive solution is included as part of the `}<inlineCode parentName="p">{`Header`}</inlineCode>{`. On screens below the XL breakpoint, the `}<inlineCode parentName="p">{`SmallHeader`}</inlineCode>{` is displayed, with the primary navigation and contact links moved to an off-canvas drawer accessible via a Menu button in the header. Try resizing the interactive demo to see how the component behaves responsively.`}</p>
    <h3 {...{
      "id": "using-custom-header-andor-footer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-custom-header-andor-footer",
        "aria-label": "using custom header andor footer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Using custom header and/or footer`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`HeaderFooterLayout`}</inlineCode>{` allows for a completely custom header or footer component to be used.`}</p>
    <p>{`These components can be provided in a number of ways:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import HeaderFooterLayout from '@nib-components/header-footer-layout';

const CustomHeader = props => <header {...props}>My custom header</header>;

const App = () => (
  <>
    <HeaderFooterLayout header={CustomHeader} /> {/* 1 */}
    <HeaderFooterLayout header={() => <CustomHeader id="custom-header-1" />} /> {/* 2 */}
    <HeaderFooterLayout header={<header id="custom-header-2">My custom header</header>} /> {/* 3 */}
  </>
);
`}</code></pre>
    <p>{`When providing a component via method `}<inlineCode parentName="p">{`1`}</inlineCode>{`, relevant props from the `}<inlineCode parentName="p">{`HeaderFooterLayout`}</inlineCode>{` are passed down to your custom header/footer. When providing a component via methods `}<inlineCode parentName="p">{`2`}</inlineCode>{` and `}<inlineCode parentName="p">{`3`}</inlineCode>{` props must be manually set on your custom JSX component.`}</p>
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#related-components",
        "aria-label": "related components permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Related components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/header/"
        }}>{`Header`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/footer/"
        }}>{`Footer`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      